<template>
  <div>
    <button v-if="referrer" class="ButtonBack"
            onclick="history.back();">
      <img src="@/assets/icons/backArrow.svg" alt="">
      Back
    </button>
    <a v-if="!referrer" class="ButtonBack"
       :href="landingUrl">
      <img src="@/assets/icons/backArrow.svg" alt="">
      Back
    </a>
    <h4 class="mb-2 mb-lg-2 title">Welcome to ConvertBomb!</h4>
    <div class="py-4">
      <form class="row g-3" @submit.prevent="login">
        <div class="col-12">
          <LandingInput
            id="userLogin"
            type="email"
            :required="true"
            @changeValue="changeValue"
            placeholder="Login"
            :value-key="'email'"
            class="w-100"
            :v="$v.loginData.email"
            :inputClass="emailInputClass"
          />
          <span class="error p-2" v-if="!$v.loginData.email.required && $v.loginData.email.$dirty">{{
              $t('error.required', { field: 'Email' })
            }}</span>
          <span class="error p-2" v-if="!$v.loginData.email.email">{{ $t('error.email', { field: 'Email' }) }}</span>
          <span class="error p-2" v-if="emailError.length">{{ emailError }}</span>
        </div>
        <div class="col-12 mt-4">
          <LandingInput
            id="password"
            type="password"
            :required="true"
            @changeValue="changeValue"
            placeholder="Password"
            :value-key="'password'"
            class="w-100"
            :v="$v.loginData.password"
          />
          <span class="error p-2"
                v-if="!$v.loginData.password.required && $v.loginData.password.$dirty"
          >
            {{ $t('error.required', { field: 'Password' }) }}
          </span>
          <span class="error p-2"
                v-if="!$v.loginData.password.minLength"
          >
            {{
              $t('error.minLength', {
                field: 'Password',
                length: 6
              })
            }}
          </span>
          <span class="error p-2" v-if="passwordError.length">{{ passwordError }}</span>
        </div>
        <div class="col-12 mt-4" style="display: flex">
          <LandingCheckbox
            :container_class="'form-check'"
            :name="'rememberMe'"
            :id="'rememberMe'"
            :label="'Remember me'"
            @change="() => {return undefined}"
            :value="true"
            :checked="undefined"
            :error="undefined"
            :fontSize="'1.14285rem'"
          >
          </LandingCheckbox>
        </div>
        <div>
          <button class="standard-btn standard-btn-success mt-4 signin-btn" type="submit">SIGN IN</button>
        </div>
        <div style="display: flex; margin-top: 3rem">
          <router-link :to="{ name: 'Forgot Password Form' }" class="link forgot-link text-decoration-none">Forgot
            password?
          </router-link>
          <router-link :to="{ name: 'SignUp' }" class='link ms-5'>Create account</router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import LandingInput from '@/components/landings/LandingInput.vue'
import LandingCheckbox from '@/components/landings/LandingCheckbox.vue'
import { required, minLength, email } from 'vuelidate/lib/validators'
import { messages } from '../../i18n/messages'
import { FACEBOOK_SERVICE } from '@/constants/FbAutomatedRule'

export default Vue.extend({
  components: {
    LandingInput,
    LandingCheckbox
  },
  metaInfo: {
    title: 'ConvertBomb | Optimize Your Digital Advertising',
    meta: [
      {
        name: 'description',
        content: 'Launch, Analyze and Automate your paid advertising on Facebook & Instagram.'
      }
    ]
  },
  props: {
    redirect: {
      required: false,
      default: null
    }
  },
  data: () => ({
    loginData: {
      email: null,
      password: null
    },
    referrer: false
  }),
  validations: {
    loginData: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6)
      }
    }
  },
  i18n: {
    messages: messages
  },
  mounted () {
    const url = new URL(document.location.href)
    this.referrer = url.searchParams.get('back')
  },
  computed: {
    ...mapGetters('auth', ['is_authorized']),
    ...mapGetters('profile', ['currentUser']),
    ...mapGetters('exceptions', ['exceptionMessageBySourceParameter']),
    emailError () {
      return this.exceptionMessageBySourceParameter('email') || ''
    },
    passwordError () {
      return this.exceptionMessageBySourceParameter('password') || ''
    },
    landingUrl () {
      return process.env.VUE_APP_LANDING_URL
    },
    emailInputClass () {
      return (this.emailError.length > 0 || this.$v.loginData.email.$error) ? 'error' : null
    },
    passwordInputClass () {
      return (this.passwordError.length > 0 || this.$v.loginData.password.$error) ? 'error' : null
    }
  },
  methods: {
    ...mapActions('auth', ['auth']),
    ...mapActions('profile', ['getProfile']),
    ...mapActions('adAccounts', ['loadAccounts']),
    ...mapActions('exceptions', ['addExceptions', 'clearExceptions']),
    ...mapActions('notifications', { addExceptionToNotifications: 'addExceptions' }),
    changeValue (data) {
      this.loginData[data.key] = data.value
      this.clearExceptions()
    },

    login () {
      if (!this.$v.$invalid) {
        this.auth({
          email: this.loginData.email,
          password: this.loginData.password
        }).then(() => {
          this.getProfile()
          this.loadAccounts({
            search: {
              service: 'facebook',
              target: 'ads_management'
            }
          })
          if (this.$gtm && this.$gtm.enabled()) {
            // eslint-disable-next-line no-unused-expressions
            window.dataLayer?.push({
              event: 'login',
              method: 'email',
              userId: this.currentUser.id
            })
          }
          if (this.redirect) {
            this.$router.push(this.redirect)
          } else {
            this.$router.push({
              name: 'AutomatedRules',
              params: { service: FACEBOOK_SERVICE }
            })
          }
        }).catch((e) => {
          if (e.response && e.response.data.errors) {
            this.addExceptionToNotifications(e.response.data)
          }
        })
      } else {
        this.$v.$touch()
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.forgot-link {
  font-size: 14px;
}

.form-check {
  padding-left: 0;
}

.title {
  font-weight: 500;
  font-size: 1.5em;
  line-height: 1.5;
  color: #2E2E2E;
}

button.signin-btn {
  margin-top: 40px;
  width: 189px;
  height: 54px;
  background: linear-gradient(180deg, #1BB469 0%, #0F9D58 81.48%);
  font-family: 'SF UI Display', sans-serif;
  border-radius: 4px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.60;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}

.link {
  font-family: "SF UI Display", sans-serif;
  font-size: 0.875rem;
  line-height: 1.43;
  color: #2364BE;
  margin: 0;
}
</style>
